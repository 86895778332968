<template>
  <div>
    <span
      class="iconify w-100 h-100"
      :key="name"
      :data-icon="name"
      data-inline="false"
    />
  </div>
</template>

<script>
export default {
  props: {
    name: String,
  },
};
</script>
