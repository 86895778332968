import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: require("@/views/Home").default,
  },
  {
    path: "/polls/:hash",
    component: require("@/views/poll/Index").default,
    props: true,
    children: [
      {
        path: "",
        name: "poll",
        redirect: "vote",
        props: true,
      },
      {
        name: "poll-vote",
        path: "vote",
        props: true,
        component: require("@/views/poll/Vote").default,
      },
      {
        path: "result",
        redirect: "results",
        props: true,
      },
      {
        name: "poll-results",
        path: "results",
        props: true,
        component: require("@/views/poll/Results").default,
      },
      {
        name: "poll-details",
        path: "details",
        props: true,
        component: require("@/views/poll/Details").default,
      },
    ],
  },
  {
    path: "*",
    name: "404",
    component: require("@/views/404").default,
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
