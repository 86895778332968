<template>
  <div class="polls p-3">
    <!-- Header -->
    <div class="flex flex--middle flex--fit flex--between mb-2">
      <h4 class="text-grey">Polls</h4>
      <div>
        <s-button
          color="primary"
          :theme="sortBy == 'createdAt' ? 'solid' : 'outline'"
          shape="pill"
          label="Recent"
          size="sm"
          @click.native="setSortBy('createdAt')"
        >
          <template #icon>
            <icon class="mr-2" name="ph:clock-duotone" />
          </template>
        </s-button>

        <s-button
          color="primary"
          :theme="sortBy == 'voteCount' ? 'solid' : 'outline'"
          shape="pill"
          label="Most Voted"
          size="sm"
          class="ml-2"
          @click.native="setSortBy('voteCount')"
        >
          <template #icon>
            <icon class="mr-2" name="ph:lightning-duotone" />
          </template>
        </s-button>
      </div>
    </div>

    <!-- List -->
    <sp-list
      endpoint="polls"
      :header="false"
      pagination-mode="infinite"
      :sort-by="sortBy"
      ref="list"
    >
      <template #loader>
        <poll-card-shimmer v-for="n in 10" :key="`loader-${n}`" />
      </template>
      <template #default="{items}">
        <poll-card
          :poll="poll"
          v-for="(poll, index) in items"
          :key="`poll-${index}`"
        />
      </template>

      <template #load-more-button="{loadMore,loading}">
        <s-button
          class="text-bold"
          size="lg"
          color="primary"
          @click.native="loadMore()"
          :loader="loading"
          fluid
          label="Load More"
        >
          <template #icon>
            <span
              class="iconify mr-2"
              data-icon="ph:caret-circle-double-down-duotone"
            />
          </template>
        </s-button>
      </template>
    </sp-list>
  </div>
</template>

<script>
export default {
  components: {
    PollCard: require("@/components/PollCard").default,
    PollCardShimmer: require("@/components/PollCardShimmer").default,
  },

  data() {
    return {
      sortBy: "createdAt",
    };
  },

  methods: {
    setSortBy(value) {
      this.sortBy = value;
      // this.$nextTick(() => {
      //   this.$refs.list.refresh();
      // });
    },
  },
};
</script>
