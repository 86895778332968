import Vue from "vue";
import router from "@/router";
import "./shilp-vue";
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import graph from "./graphql";

Vue.prototype.$graph = graph;

//TODO Configure Voliz Analytics
import VueGtag from "vue-gtag";

if (process.env.VUE_APP_ENV == "production") {
  Vue.use(
    VueGtag,
    {
      config: {
        id: process.env.VUE_APP_GOOGLE_ANALYTICS,
      },
    },
    router
  );

  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

import PortalVue from "portal-vue";
Vue.use(PortalVue);
