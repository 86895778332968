export default {
  props: {
    poll: Object,
  },
  computed: {
    status() {
      return this.isActive ? "Running" : "Ended";
    },

    isActive() {
      return this.poll.status == "running" || this.poll.status == "active"
        ? true
        : false;
    },

    pollRoute() {
      if (this.isActive) {
        return { name: "poll", params: { hash: this.poll.hash } };
      } else {
        return { name: "poll-results", params: { hash: this.poll.hash } };
      }
    },

    isResultVisible() {
      if (this.poll.resultPrivacy == "everyone") {
        return true;
      } else if (this.poll.isVoter) {
        return true;
      }
      return false;
    },

    shareLink() {
      return `https://wa.me/?text=${encodeURIComponent(this.poll.message)}`;
    },
  },
};
