<template>
  <div id="app" class="app" :class="appClass">
    <app-header />
    <div class="content">
      <router-view />
    </div>
    <app-footer />
    <s-modal-container />
  </div>
</template>

<script>
export default {
  components: {
    AppHeader: require("@/components/Header").default,
    AppFooter: require("@/components/Footer").default,
  },
  computed: {
    appClass() {
      if (this.$route.name) {
        return `app--${this.$route.name}`;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.app--home {
  background-color: --color(grey, lightest);
}
</style>
