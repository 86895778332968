import Vue from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import "@/scss/style.scss";
import "@animxyz/core";
import "@/plugins";
import Icon from "@/components/Icon";
import VueAnimXYZ from "@animxyz/vue";

Vue.use(VueAnimXYZ);
Vue.component("Icon", Icon);
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
