<template>
  <div class="result-options">
    <div
      class="result-option mb-2 bg-light radius-3 p-3"
      :class="{ 'result-option--most-voted': option.rank == 1 }"
      v-for="(option, index) in options"
      :key="option.hash"
    >
      <!-- Top -->
      <div class="flex flex--nowrap">
        <h5 class="index flex__fit">{{ index + 1 }}.</h5>
        <h5 class="flex__fluid">{{ option.label }}</h5>
      </div>

      <!-- Bar -->
      <div class="bg-grey-lighter radius-2 mt-3">
        <div
          class="bg-secondary  radius-2 h-1"
          :style="{ width: option.share + '%' }"
        />
      </div>

      <!-- Analytics -->
      <poll-option-analytics class="mt-3" :option="option" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: Array,
  },
  components: {
    PollOptionAnalytics: require("@/components/PollOptionAnalytics").default,
  },
};
</script>

<style lang="scss">
.result-option {
  .index {
    font-weight: bold;
    color: --color("grey", "light");
    margin-right: --space(3);
  }
}

.result-option--most-voted {
  box-shadow: 0 0 0 2px --color(warning, lighter);
}
</style>
