import { render, staticRenderFns } from "./PollCard.vue?vue&type=template&id=518038b6&scoped=true&"
import script from "./PollCard.vue?vue&type=script&lang=js&"
export * from "./PollCard.vue?vue&type=script&lang=js&"
import style0 from "./PollCard.vue?vue&type=style&index=0&id=518038b6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "518038b6",
  null
  
)

export default component.exports