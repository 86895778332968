<template>
  <div class="text-center">
    <icon class="w-6 m-auto" :name="icon" />
    <h3 class="text-grey mt-3">{{ title }}</h3>
    <div class="text-grey">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    title: String,
  },
};
</script>
