<template>
  <div class="bg-grey-lightest p-4">
    <!-- Created By -->
    <div>
      <label class="text-grey">Created By</label>
      <poll-author class="mt-2" :creator="poll.creator" />
    </div>

    <!-- Analytics -->
    <div class="mt-4">
      <label class="text-grey ">Analytics</label>
      <poll-analytics class="mt-2" :poll="poll" />
    </div>

    <!-- Most Voted -->
    <div class="mt-4" v-if="isResultVisible">
      <label class="text-grey ">Most Voted</label>
      <poll-result-options class="mt-2" :options="poll.mostVoted" />
    </div>
  </div>
</template>

<script>
import pollMixin from "@/mixins/poll";

export default {
  mixins: [pollMixin],
  components: {
    PollAuthor: require("@/components/PollAuthor").default,
    PollAnalytics: require("@/components/PollAnalytics").default,
    PollResultOptions: require("@/components/PollResultOptions").default,
  },
};
</script>
