<template>
  <alert-card
    class="p-6 radius-4 text-center"
    icon="emojione:ghost"
    title="Not Found!"
  >
    <p>
      Looks like the page you're looking is lost and can not be found. Why don't
      you explore what's been there on Home Page?
    </p>
    <s-button color="primary" fluid size="lg" class="text-bold mt-3" to="/">
      Go To Home
    </s-button>
  </alert-card>
</template>

<script>
export default {
  components: {
    AlertCard: require("@/components/AlertCard").default,
  },
};
</script>
