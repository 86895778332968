<template>
  <div class="options">
    <div
      class="option flex flex--nowrap mb-2 p-3 radius-3"
      v-for="(option, index) in options"
      :key="`${option.hash}-${value.includes(option.hash)}`"
      :class="{ 'option--active': value.includes(option.hash) }"
      @click="input(option.hash)"
    >
      <!-- Index -->
      <h5 class="flex__fit index mr-3">{{ index + 1 }}.</h5>

      <!-- Label -->
      <h5 class="flex__fluid">{{ option.label }}</h5>

      <!-- Checked -->
      <div class="flex__fit ml-auto w-4 h-4 ml-3">
        <icon
          v-if="value.includes(option.hash)"
          class="icon"
          :name="icons.checked"
        />

        <!-- Unchecked -->
        <icon v-else class="icon" :name="icons.default" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
    options: Array,
    multiple: Boolean,
  },

  computed: {
    icons() {
      return {
        checked: this.multiple
          ? "ph:check-square-duotone"
          : "ph:check-circle-duotone",
        default: this.multiple ? "ph:square-duotone" : "ph:circle-duotone",
      };
    },
  },

  methods: {
    input(hash) {
      if (this.multiple) {
        const index = this.value.indexOf(hash);
        const newValue = [...this.value];
        if (index > -1) {
          newValue.splice(index, 1);
        } else {
          newValue.push(hash);
        }
        this.$emit("input", newValue);
      } else {
        this.$emit("input", [hash]);
      }
    },
  },
};
</script>

<style lang="scss">
.option {
  background-color: #fff;
  cursor: pointer;
  align-items: center;

  .index {
    font-weight: bold;
    color: --color("grey", "light");
  }

  .icon {
    color: --color("grey", "light");
    display: flex;
  }
}

.option--active {
  background-color: --color("primary");
  color: --color("light");
  .index {
    color: --color("primary", "dark");
  }
  .icon {
    color: --color("primary", "darker");
  }
}
</style>
