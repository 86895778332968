<template>
  <div class="option-analytics flex flex--fit">
    <!-- Rank -->
    <div>
      <small>Rank</small>
      <p>{{ option.rank === 0 ? "∞" : option.rank }}</p>
    </div>

    <!-- Votes -->
    <div class="ml-3">
      <small>Votes</small>
      <p>{{ option.votes }}</p>
    </div>

    <!-- Status -->
    <div class="ml-3">
      <small>Share</small>
      <p>{{ option.share }}%</p>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    option: Object,
  },
};
</script>

<style lang="scss">
.option-analytics {
  small {
    color: --color(grey);
  }
}
</style>
