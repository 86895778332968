<template>
  <div>
    <div class="bg-grey-lightest p-4">
      <!-- Options -->
      <div v-if="isActive">
        <div class="text-grey">
          <label>Select Your Vote</label>
          <p class="text-grey text-xs" v-if="poll.multipleVotes">
            You can choose multiple
          </p>
        </div>
        <poll-options
          class="mt-2"
          v-model="selected"
          :options="poll.options"
          :multiple="poll.multipleVotes"
        />
      </div>

      <!-- Ended -->
      <alert-card
        class="p-5"
        icon="emojione:hourglass-done"
        title="Timeout!"
        v-else
      >
        The poll has been ended and not accepting new votes anymore.
      </alert-card>
    </div>

    <send-vote
      :votes="selected"
      @reset="selected = []"
      :channel="poll.channel"
    />
  </div>
</template>

<script>
import pollMixin from "@/mixins/poll";

export default {
  mixins: [pollMixin],
  components: {
    SendVote: require("@/components/SendVote").default,
    PollOptions: require("@/components/PollOptions").default,
    AlertCard: require("@/components/AlertCard").default,
  },

  data() {
    return {
      selected: [],
    };
  },
};
</script>
