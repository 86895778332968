import Vue from "vue";
import ShilpVue, { ListPlugin } from "shilp-vue";
import graph from "@/plugins/graphql";
import collections from "@/graph/collections";

Vue.use(ShilpVue);

Vue.use(ListPlugin, {
  requestHandler(data) {
    const { endpoint, params, filters, search, pagination, sort } = data;

    const vars = {
      ...params,
      search,
      perPage: pagination.perPage,
      page: pagination.page,
      filters,
      sort: sort,
    };

    return graph(collections[endpoint])(vars)
      .then((res) => {
        //The collection has only key
        //So we can get it from first index.
        const keys = Object.keys(res);
        res = res[keys[0]];
        return {
          items: res.data,
          count: res.pagination.total,
        };
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  },
});
