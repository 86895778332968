<template>
  <router-link class="poll" :to="pollRoute">
    <!-- Question -->
    <p class="question text-md">{{ poll.question }}</p>

    <!-- Analytics -->
    <poll-card-analytics class="bt-lightest pt-3 mt-3" :poll="poll" />
  </router-link>
</template>

<script>
import pollMixin from "@/mixins/poll";

export default {
  mixins: [pollMixin],
  components: {
    PollCardAnalytics: require("@/components/PollCardAnalytics").default,
  },
  props: {
    poll: Object,
  },
};
</script>

<style lang="scss" scoped>
.poll {
  background-color: --color(light);
  margin-top: --space(2);
  border-radius: --space(2);
  padding: --space(4);
  display: block;
  text-decoration: none !important;
}
.question {
  white-space: pre-line;
}

.poll__footer {
  margin-top: --space(3);
  border-top: 1px solid --color(grey, lightest);
  padding-top: --space(3);
  small {
    color: --color(grey);
  }
  h4 {
    line-height: 1;
  }
}
</style>
