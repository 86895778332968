<template>
  <poll-shimmer v-if="loading" />
  <div v-else-if="pollData">
    <!-- Poll -->
    <div class="p-4">
      <label class="text-grey">Question</label>
      <h4>{{ pollData.question }}</h4>
    </div>

    <div class="bg-light grid grid--3 px-2">
      <s-button
        label="Register Vote"
        color="primary"
        theme="tab"
        :to="{ name: 'poll-vote', params: { hash } }"
      />
      <s-button
        label="Details"
        color="primary"
        theme="tab"
        :to="{ name: 'poll-details', params: { hash } }"
      />
      <s-button
        label="Results"
        color="primary"
        theme="tab"
        :to="{ name: 'poll-results', params: { hash } }"
      />
    </div>

    <router-view :poll="pollData" />

    <poll-footer :poll="pollData" />
  </div>
</template>

<script>
import get from "@/graph/poll/get.gql";
import pollMixin from "@/mixins/poll";

export default {
  mixins: [pollMixin],
  props: {
    hash: String,
  },
  components: {
    PollShimmer: require("@/components/PollShimmer").default,
    PollFooter: require("@/components/PollFooter").default,
  },

  data() {
    return {
      pollData: null,
      loading: true,
      selected: [],
    };
  },

  async created() {
    this.setToken();

    if (this.hash) {
      try {
        this.loading = true;
        const { poll } = await this.$graph(get)({
          pollHash: this.hash,
          authHash: this.getToken(),
        });
        this.pollData = poll;
        this.loading = false;
      } catch (err) {
        console.error(err);
        this.$router.push({ name: "404" });
      }
    }
  },

  methods: {
    setToken() {
      if (this.$route.query.token) {
        localStorage.setItem(
          this.hash,
          JSON.stringify({ token: this.$route.query.token })
        );
      }
    },

    getToken() {
      return JSON.parse(localStorage.getItem(this.hash))?.token;
    },
  },
};
</script>

<style lang="scss" scoped>
.checked {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: --space(3);
  flex: 0 0 auto;
  font-size: 20px;
}
.result {
  td,
  th {
    &:nth-child(1),
    &:nth-child(2) {
      text-align: left;
    }
    &:nth-child(3),
    &:nth-child(4) {
      text-align: right;
    }
  }
}
.total {
  text-align: right !important;
}
</style>
