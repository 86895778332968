<template>
  <footer>
    <download />
    <footer class="footer px-3 py-5">
      <a href="/">
        <img height="24px" src="@/img/logo-white.svg" alt="Voliz Logo" />
      </a>
      <p class="text-grey-light mt-3">
        With Voliz, you can create polls quickly and share it on WhatsApp.
        People can vote directly via WhatsApp without a need of Voliz App.
      </p>
      <p class="mt-3 text-grey-light">
        Product of <a href="https://7span.com" target="_blank">7Span</a>
      </p>
      <icon class="vote-emoji" name="emojione-v1:ballot-box-with-ballot" />
    </footer>
  </footer>
</template>

<script>
export default {
  components: {
    Download: require("@/components/Download").default,
  },
};
</script>

<style lang="scss">
.footer {
  position: relative;
  text-decoration: none;
  overflow: hidden;
  background-color: #111827;
}
.vote-emoji {
  width: 100px;
  height: 100px;
  position: absolute;
  bottom: -20px;
  right: -20px;
}
</style>
