<template>
  <div class="field-group g-2 bg-light p-5">
    <!-- SHARE -->
    <s-button
      class="text-bold"
      color="primary"
      fluid
      size="lg"
      align="right"
      :href="shareLink"
      theme="outline"
      target="_blank"
      label="Share on WhatsApp"
    >
      <template #icon>
        <icon class="ml-1" name="mdi:whatsapp" />
      </template>
    </s-button>

    <!-- EXPLORE -->
    <s-button
      class="text-bold"
      color="info"
      theme="outline"
      align="right"
      fluid
      label="Explore Public Polls"
      to="/"
      size="lg"
    >
      <template #icon>
        <icon class="ml-1" name="ph:globe-hemisphere-east-duotone" />
      </template>
    </s-button>
  </div>
</template>

<script>
import pollMixin from "@/mixins/poll";

export default {
  mixins: [pollMixin],
};
</script>
