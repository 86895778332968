<template>
  <div class="flex flex--middle bg-light radius-3 p-3">
    <div
      class="w-5 h-5 radius-circle flex__fit mr-3 flex flex--middle flex--center text-xl bg-primary-lightest text-primary"
    >
      <span class="iconify " data-icon="ph:user-circle-duotone"></span>
    </div>
    <div class="flex__fluid">
      <!-- <label class="text-xs text-grey">Created by</label> -->
      <h4>{{ creator.name || "Anonymous" }}</h4>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    creator: Object,
  },
};
</script>
