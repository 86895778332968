<template>
  <div class="send" :class="{ 'send--open': votes.length > 0 }">
    <XyzTransition appear xyz="down-100 duration-2">
      <div v-if="votes.length > 0" class="bg-light p-4 send__content">
        <!-- Heaader -->
        <div class="mb-2 flex flex--between flex--fit flex--middle">
          <h4>Almost Done...</h4>
          <s-button
            class="flex__fit"
            size="sm"
            color="grey"
            theme="muted"
            label="Close"
            align="right"
            @click.native="$emit('reset')"
          >
            <template #icon>
              <span class="iconify ml-1" data-icon="ph:x-circle-duotone" />
            </template>
          </s-button>
        </div>

        <div class="flex flex--middle flex--between flex--nowrap">
          <div class="w-5 flex__fit mr-2">
            <div class="w-100 h-100 iconify " data-icon="logos:whatsapp" />
          </div>
          <p class="text-xs">
            To register your vote, we will open WhatsApp to send a message to
            Official <b>Voliz</b> Number.
          </p>
        </div>

        <s-button
          class="text-bold text-md mt-3"
          align="right"
          size="xl"
          color="warning"
          fluid
          label="Register My Vote"
          :href="link"
          target="_blank"
        >
          <template #icon>
            <icon class="ml-1" name="ph:caret-double-right-duotone" />
          </template>
        </s-button>
      </div>
    </XyzTransition>
  </div>
</template>

<script>
export default {
  props: {
    votes: Array,
    channel: Object,
  },

  computed: {
    link() {
      const votes = this.votes.join("-");
      const text = `:::${process.env.VUE_APP_ORG}:::VOTE:::${votes}:::`;
      return `https://wa.me/${this.channel.value}?text=${text}`;
    },
  },
};
</script>

<style lang="scss">
.send {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

.send--open {
}

.send__content {
  border-top: 3px solid --color(warning);
  box-shadow: 0 -20px 50px 0 rgba(0, 0, 0, 0.1);
}
</style>
