<template>
  <div class="bg-grey-lightest p-4">
    <!--  -->

    <!-- Options -->
    <poll-result-options v-if="isResultVisible" :options="poll.options" />

    <!-- Hidden -->
    <alert-card v-else class="p-5" icon="emojione:locked" title="">
      <p>
        The results are locked for this poll. Only poll creator can see the
        results.
      </p>
    </alert-card>
  </div>
</template>

<script>
import pollMixin from "@/mixins/poll";

export default {
  mixins: [pollMixin],
  components: {
    PollResultOptions: require("@/components/PollResultOptions").default,
    AlertCard: require("@/components/AlertCard").default,
  },
};
</script>
