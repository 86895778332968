<template>
  <div class="poll-analytics grid grid--2 grid--fluid g-1">
    <!-- Votes -->
    <div class="poll-analytics__card">
      <small>Votes</small>
      <h4 v-if="isResultVisible">{{ poll.voteCount }}</h4>
      <icon v-else name="ph:lock-duotone" class="w-4 text-grey" />
    </div>

    <!-- Voters -->
    <div class="poll-analytics__card">
      <small>Voters</small>
      <h4 v-if="isResultVisible">{{ poll.voterCount }}</h4>
      <icon v-else name="ph:lock-duotone" class="w-4 text-grey" />
    </div>

    <!-- Status -->
    <div class="poll-analytics__card">
      <small>Status</small>
      <h4
        class="text-capitalize"
        :class="isActive ? 'text-primary' : 'text-danger'"
      >
        {{ status }}
      </h4>
    </div>

    <!-- Created -->
    <div class="poll-analytics__card">
      <small>Created</small>
      <h4><datetime :value="poll.createdAt" /></h4>
    </div>

    <!-- Expired -->
    <div class="poll-analytics__card" v-if="poll.expiredOn">
      <small>Expiry</small>
      <h4><datetime :value="poll.expiredOn" /></h4>
    </div>
  </div>
</template>

<script>
import pollMixin from "@/mixins/poll";

export default {
  mixins: [pollMixin],
  components: {
    Datetime: require("@/components/Datetime").default,
  },
  props: {
    poll: Object,
  },
};
</script>

<style lang="scss">
.poll-analytics {
  small {
    color: --color(grey);
  }
}

.poll-analytics__card {
  background-color: #fff;
  border-radius: --radius(3);
  padding: --space(3);
}
</style>
