<template>
  <span v-if="relative">{{ relativeValue }}</span>
  <span v-else>{{ dateValue }}</span>
</template>

<script>
import { format, formatDistanceStrict } from "date-fns";

export default {
  props: {
    value: [String, Number],
    relative: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    relativeValue() {
      return formatDistanceStrict(parseInt(this.value) * 1000, new Date(), {
        addSuffix: true,
      });
    },
    dateValue() {
      return format(parseInt(this.value) * 1000, "dd/MM/yyyy hh:mm:ss a");
    },
  },
};
</script>
