<template>
  <div class="poll-card-analytics list list--fluid list--fit g-3">
    <!-- Votes -->
    <div>
      <small>Votes</small>
      <p v-if="isResultVisible">{{ poll.voteCount }}</p>
      <icon v-else name="ph:lock-duotone" class="w-3 text-grey" />
    </div>

    <!-- Status -->
    <div>
      <small>Status</small>
      <p
        class="text-capitalize"
        :class="isActive ? 'text-primary' : 'text-danger'"
      >
        {{ status }}
      </p>
    </div>

    <!-- Created -->
    <div>
      <small>Created</small>
      <p><datetime :value="poll.createdAt" /></p>
    </div>

    <!-- Expired -->
    <div v-if="poll.expiredOn">
      <small>Expiry</small>
      <p><datetime :value="poll.expiredOn" /></p>
    </div>
  </div>
</template>

<script>
import pollMixin from "@/mixins/poll";

export default {
  mixins: [pollMixin],
  components: {
    Datetime: require("@/components/Datetime").default,
  },
  props: {
    poll: Object,
  },
};
</script>

<style lang="scss">
.poll-card-analytics {
  overflow: auto;
  small {
    color: --color(grey);
  }
}
</style>
